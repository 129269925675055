@use 'src/styles/font';
@use 'src/styles/dimension';
@use 'src/styles/spacing';

.SaveSummarisedPromptNotificationDialog {
  & .MuiPaper-root {
    padding: spacing.$l-100;
    margin: 0;

    .MuiTypography-root {
      padding: 0;
    }
  }

  &_Header {
    display: flex;
    justify-content: space-between;

    &Title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: spacing.$s-100;
      align-self: stretch;

      .MuiTypography-root:first-child {
        @include font.semibold(h6);
      }
      .MuiTypography-root:nth-child(2) {
        @include font.regular(small);
        color: var(--color-text-subtle);
      }
    }

    &Icon {
      cursor: pointer;
      color: var(--color-icon-subtlest);
    }
  }

  &_Content {
    &.MuiDialogContent-root {
      padding: 0;
    }

    &Image {
      margin: spacing.$l-200 0;
      border-radius: dimension.$xs;
      border: 1px solid var(--color-border-default);
    }

    &Help {
      display: flex;
      gap: spacing.$s-100;

      .MuiSvgIcon-root {
        color: var(--color-icon-information);
      }

      .MuiTypography-root {
        @include font.regular;
        color: var(--color-text-subtle);
        display: flex;

        .helpLink.MuiTypography-root {
          margin-left: spacing.$xs-100;
          color: var(--color-text-information);
        }
      }

      &Link {
        color: var(--color-text-information);
      }
    }
  }
}