@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/dimension';

.addUsersDialog {

  &_TitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_CloseIcon {
    color: var(--color-icon-subtlest);
    cursor: pointer;
  }

  &_Content.MuiDialogContent-root {
    padding-bottom: spacing.$l-100;
  }

  &_Title {
    @include font.typography-semibold(h6);
  }

  &_Description {
    @include font.typography-regular(small);
    padding-bottom: spacing.$l-100;
  }

  &_InputContainer {
    display: flex;
    gap: spacing.$s-100;
    width: 100%;
    align-items: flex-start;
  }

  &_TextfieldContainer {
    flex-basis: 85%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &_ButtonContainer {
    flex-basis: 15%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &_Button {
    @include button.default
  }

  &_InfoIcon.MuiSvgIcon-root {
    width: dimension.$s-200;
    margin-right: spacing.$xs-100;
  }
}

.usersAddedAccordion {

  &_Container.MuiPaper-root {
    border-radius: dimension.$xs-100;
    border: 1px solid var(--color-grey-200);
    background: var(--color-white);
    box-shadow: none;
    margin-top: spacing.$m-100;
  }

  &_Title {
    @include font.typography-semibold(base);
  }

  &_Details.MuiAccordionDetails-root {
    padding: 0;
  }

  &_List.MuiList-root{
    padding: 0;
  }

  &_Divider.MuiDivider-root {
    margin: 0 spacing.$m-100;
  }

  &_ListItem > div > span {
    @include font.regular(base);
  }

  &_ListIcon > svg {
    color: var(--global-purple-color);
  }
}
