.groupsListContainer {

  .groupItem {
    margin-bottom: 10px;
    border-radius: 4px;

    &:hover {
      background: rgba(128, 182, 188, 0.10);
    }

    &.noGroup {
      cursor: inherit;

      &:hover {
        background-color: transparent;
      }
    }

  }

  .groupText {
    color: #44546F;
    font-size: 14px;

    & > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .groupAvatarIconContainer {
    min-width: 28px;
  }

  .groupAvatarIcon {
    width: 24px;
    height: 24px;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
  }

  .groupMembersCount {
    border-radius: 3px;
    background: #44546F;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    width: 24px;
    min-width: 24px;
    height: 24px;

    & .MuiChip-label {
      color: #FFFFFF;
      padding: 0;
    }
  }

  .groupDivider {
    margin-right: 10px;
  }

}

