@use 'src/styles/admin';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/dimension';

.usersListsContainer {
  @include admin.container;

  .title.MuiTypography-root {
    @include admin.title;
    margin-bottom: spacing.$l-100;
  }

  .subtitle.MuiTypography-root {
    @include admin.titleDescription;
    margin-bottom: spacing.$l-200;
  }
}

.MuiChip-avatarMedium.MuiChip-avatar.aiIconModelDisabled {
  width: dimension.$m-100;
}

.MuiTypography-body2.userFullName, .userMailCell, .disabledModelsCell > .MuiTypography-body1 {
  @include font.regular(small)
}

.actionIcon {
  color: var(--color-icon-brand);
}

.MuiChip-root.chipColorGoogleGemini {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #7E8DBC;
  }

  border-color: #7E8DBC;
}


.MuiChip-root.chipColorgpt-3\.5-turbo {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #70628A;
  }

  border-color: #70628A;
}

.MuiChip-root.chipColorgpt-4-0125-preview {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #236068;
  }

  border-color: #236068;
}

.MuiChip-root.chipColorLlama-2 {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #B57B93;
  }

  border-color: #B57B93;
}

.MuiChip-root.chipColorMistral {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #B69386;
  }

  border-color: #B69386;
}

.MuiChip-root.chipColorAmazon-Q {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #632E56;
  }

  border-color: #632E56;
}

.MuiChip-root.chipColorclaude-3-haiku-20240307 {
  & > .MuiChip-label.MuiChip-labelMedium, & > svg {
	color: #9F8A73;
  }

  border-color: #9F8A73;
}

.userNameCell {
  gap: spacing.$xs-200;
  align-items: center;
  flex-direction: row;
  display: flex;
}

.disabledModelsCell {
  gap: spacing.$xs-200;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart {
  border: none;
  box-shadow: none;
}

.tooltipContainer {
  border-radius: dimension.$s-100;
  border: 1px solid rgba(68, 84, 111, 0.20);
  background: #F9F9FB;
  white-space: pre-line;
}

.tooltipModelNameContainer {
  display: flex;
  flex-direction: row;
  gap: dimension.$xs-100;
  align-items: center;

  & svg {
    width: dimension.$s-200;
  }
}