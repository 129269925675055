@use 'src/styles/font';
@use 'src/styles/dimension';
@use 'src/styles/spacing';

.SaveSummarisedPromptNotification {
  &_Container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: spacing.$s-100;
  }

  &_Arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--color-surface-brand-light);
  }

  &_Box {
    display: flex;
    padding: spacing.$s-200 spacing.$m-100;
    flex-direction: column;
    align-items: flex-start;
    gap: spacing.$m-100;
    align-self: stretch;
    background: var(--color-surface-brand-light);

    &Text.MuiTypography-root {
      @include font.regular(xsmall)
    }
    
    &Actions {
      display: flex;
      gap: spacing.$xs-100;

      &ViewMore.MuiButtonBase-root {
        @include font.medium(xsmall);
        color: var(--color-text-brand);
        padding: 0;
      }

      &Dismiss.MuiButtonBase-root {
        @include font.medium(xsmall);
        color: var(--color-text-subtlest);
        padding: 0;
      }
    }
  }
}