@use 'src/styles/panel';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/button';

.AccountCreationError {
    @include panel.default;
    padding: spacing.$xxl-100 spacing.$xxl-200;

    &_Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: spacing.$l-200;
        height: 60vh;
        gap: 36px;
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: spacing.$l-100;
    }

    &_Icon {
        color: var(--color-icon-brand);
        &.MuiSvgIcon-root {
            font-size: spacing.$xl-200;
        }
    }

    &_Title {
        @include font.typography-semibold(h6);
    }

    &_Subtitle {
        @include font.typography-regular;
        text-align: center;
        width: 70%;
    }

    &_ReloadButton {
        @include button.default;

        &.MuiButton-root {
            margin-top: spacing.$xl-200;
        }
    }
}
