@use 'src/styles/spacing';
@use 'src/styles/font';

@mixin container {
    padding: spacing.$l-200;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@mixin title {
    @include font.typography-semibold(h6);
}

@mixin subtitle {
    @include font.typography-medium(small);
}

@mixin titleDescription {
    @include font.typography-regular;
}

@mixin sectionTitle {
    @include font.typography-semibold(large);
}

