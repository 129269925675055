@use 'src/styles/spacing';
@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/panel';
@use 'src/styles/accountCreation';

.AccountCreationForm {
    padding: spacing.$xxl-400 spacing.$xl-200;
    gap: spacing.$l-200;
    overflow-y: auto;

    &_Title.MuiTypography-root {
        @include accountCreation.title;
    }

    &_ControlSubtitle {
        @include accountCreation.subtitle;
        text-align: center;
        justify-items: center;
    }

    &_ControlCaption.MuiTypography-root {
        @include font.regular(small);
        color: var(--color-text-subtle);
        text-align: center;
    }

    &_Controls {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    &_Actions {
        display: flex;
        margin-top: spacing.$xxl-300;
        gap: spacing.$m-100;
    }

    &_DomainInput {
        display: flex;
        align-items: flex-start;
        gap: spacing.$s-100;
        justify-content: space-between;
        width: 100%;
    }

    &_Domains {
        display: flex;
        padding: spacing.$m-100;
        flex-direction: column;
        gap: spacing.$m-100;

        border-radius: spacing.$xs-200;
        border: 1px solid var(--color-border-default);
        width: 100%;

        &Error {
            display: flex;
            padding: spacing.$s-100 spacing.$m-100;
            align-items: center;
            gap: spacing.$l-100;
            align-self: stretch;
            border-radius: spacing.$xs-100;
            background: var(--color-surface-danger-light);

            &Wrapper {
                display: flex;
                align-items: center;
                gap: spacing.$xs-100;
                flex: 1 0 0;
            }

            &Message {
                @include font.typography-regular(xsmall);
            }

            &Icon {
                color: var(--color-icon-danger);
            }

            &CloseIcon {
                color: var(--color-icon-subtlest);
                cursor: pointer;
            }
        }

        > div:not(:last-child) {
            border-bottom: 1px solid var(--color-border-divider);
            padding-bottom: spacing.$m-100;
        }
    }

    &_Domain {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: spacing.$s-100;
        width: 100%;

        .MuiTypography-root {
            @include font.typography-regular(small);
        }

        .MuiDivider-root {
            color: var(--color-border-divider);
        }

        .MuiSvgIcon-root {
            color: var(--color-icon-brand);
        }
    }

    &_AddAdminWrapper {
        width: 100%;
    }

    &_AddAdminHelp.MuiTypography-root {
        @include font.regular(xsmall);
        color: var(--color-text-subtle);
    }

    &_AddAdminContainer {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: spacing.$s-100;
        width: 100%;
    }

    &_InputField {
        flex-grow: 1;
        border-color: var(--color-border-default);
    }

    &_AddAdminButton.MuiButtonBase-root {
        padding: spacing.$s-100 spacing.$l-100;
    }

    &_ListContainer {
        width: 100%;
        border-radius: spacing.$xs-200;
        border: 1px solid var(--color-border-default);
        margin-top: spacing.$l-200;
        padding: spacing.$s-100 spacing.$m-100;
    }

    &_Control {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$l-100;
        width: 100%;
    }

    &_Actions {
        text-align: center;
    }

    &_Action {
        @include button.default;
    }
}
