@use 'src/styles/spacing';

body {
  margin: 0;
  font-family: var(--global-font-family);
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  background-color: #FFFFFF;
}

.App {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


input:focus {
  --tw-ring-shadow: 0 0 #000 !important;
}