@use 'src/styles/admin';
@use 'src/styles/spacing';
@use 'src/styles/button';
@use 'src/styles/font';

.usersListsContainer {
  @include admin.container;

  .title.MuiTypography-root {
    @include admin.title;
    margin-bottom: spacing.$l-100;
  }

  .subtitle.MuiTypography-root {
    @include admin.titleDescription;
    margin-bottom: spacing.$xl-100;
  }

  .addUsersButton {
    @include button.default;
  }
}

.userNameCell, .userMailCell, .userTeamCell, .userRoleCell, .userLastActiveCell, .userDateAddedCell {
  @include font.regular(small);
}

.actionIcon {
  color: var(--color-icon-brand);
}