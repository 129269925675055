@use 'src/styles/spacing';

.chatCardsContainer {
  padding: spacing.$l-200;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chatCard {
  width: 24%;
  height: 12rem;

  &:hover {
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .chatCard {
	width: 32%;
  }
}


@media only screen and (max-width: 800px) {
  .chatCard {
	width: 49%;
  }
}

.chatLabels.MuiCardContent-root {
  display: flex;
  align-items: center;
}

.chatLabels.MuiCardContent-root, .chatLabels.MuiCardContent-root:last-child {
  padding: 20px 0 0 0;
}

.chatTitle.MuiTypography-root {
  margin-bottom: 0;
  color: rgba(9, 30, 66, 0.87);
  font-size: 20px;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.chatDescription.MuiTypography-root {
  flex-grow: 1;
  color: rgba(9, 30, 66, 0.76);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.chatsCardsWrapper.MuiStack-root {
  width: 100%;
  margin-top: 20px;
  align-items: center;
  column-gap: 12px;
  row-gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.chatTitleContainer {
  display: flex;
  justify-content: space-between;
}

.deleteIcon.MuiIconButton-root {
  padding: 0;

  & > svg {
    color: var(--global-primary-color);
  }
}

.searchContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  .searchField {
    background: rgba(241, 242, 244, .1);
    border-radius: 5px;
    width: 350px;

    & svg {
      fill: rgba(9, 30, 66, 0.70);
    }
    & .MuiInputBase-formControl {
      border-radius: 5px;
      &.MuiFilledInput-root:hover:before{
        border: 0;
      }

      &:before, &:after {
        border: 0;
      }
    }

    & .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium {
      margin-top: 0;
      margin-right: 0;
    }

    & .MuiInputBase-input {
      color: rgba(9, 30, 66, 0.60);
      padding: 16.5px 11px;

      &::placeholder {
        opacity: 1;
      }
    }
  }
}