@use 'src/styles/spacing';

.promptsCardsContainer {
  padding: spacing.$l-200;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .promptsCardsWrapper.MuiStack-root {
    margin-top: 20px;
    align-items: center;
    column-gap: 50px;
    row-gap: 23px;
  }
  
  .loadMoreButton {
    margin-top: 30px;
    border-radius: 3px;
    border: 1px solid var(--global-primary-color);
  }
}