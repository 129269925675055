@use 'src/styles/button';
@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/tooltip';

.fileUploadChips {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: spacing.$m-100;
    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }
}

.fileUploadChip {
    &.MuiChip-root {
        border-radius: dimension.$xs;
        padding: spacing.$s-100;
        height: unset;
        width: 235px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & .MuiChip-label {
            flex-grow: 1;
        }
    }
    & .MuiChip-deleteIcon {
        margin: 0;
    }
    &.MuiChip-root {
        background-color: var(--color-surface-accent-light);
        .MuiChip-label {
            color: var(--color-text-default);
        }
        .MuiChip-deleteIcon {
            color: var(--color-icon-subtlest);
            width: spacing.$m-200;
            height: spacing.$m-200;
            opacity: 0.7;
            &:hover {
                color: var(--color-icon-subtle);
            }
        }
        .MuiChip-icon {
            color: var(--color-icon-subtlest);
        }
        &.MuiChip-colorInfo {
            opacity: 0.7;
        }
        &.MuiChip-colorError {
            background-color: var(--color-surface-danger-light);
            
        }
    }
    &_LabelContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &_Subtitle {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$s-100;

        &.--spaced {
            justify-content: space-between;
        }
    }
    &_LabelTitle.MuiTypography-root {
        @include font.semibold(xsmall);
    }
    &_LabelSubtitle.MuiTypography-root {
        @include font.regular(xsmall);
        color: var(--color-text-subtlest);
    }
    &_LabelError.MuiTypography-root {
        @include font.regular(xsmall);
        color: var(--color-text-danger);

    }
    &_ErrorTooltip {
        @include tooltip.error;
    }
    &_RetryButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$xs-200;
        cursor: pointer;

        & .MuiTypography-root {
            @include font.medium(xsmall);
            color: var(--color-text-brand);
        }
        & .MuiSvgIcon-root {
            width: 14px;
            height: 14px;
            color: var(--color-icon-brand);
        }

        &:hover {
            & .MuiTypography-root {
                color: var(--color-text-brand-contrast);
            }    
            & .MuiSvgIcon-root {
                color: var(--color-icon-brand-contrast);
            }   
        }
    }
}