@use 'src/styles/spacing';
@use 'src/styles/font';

.editBudget {

	&_Container {
		padding: spacing.$xl-100;
		width: 40vw;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&_TitleContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: spacing.$xl-200;
	}

	&_Title {
		display: flex;
		align-items: center;

		& .MuiTypography-root {
			@include font.semibold(h6);
			margin: 0 spacing.$s-100;
		}
	}

	&_CloseIcon {
		color: var(--color-icon-subtle)
	}

	&_TitleIcon {
		& .MuiSvgIcon-root {
			color: var(--color-icon-brand);
		}
	}

  &_Subtitle.MuiTypography-root {
	padding-bottom: spacing.$m-100;
	@include font.semibold;
  }

  &_AmountLabel.MuiTypography-root {
	@include font.medium(xsmall);
	color: var(--color-text-subtle);
  }

  &_CostAlert, &_CycleAlert {
	margin: spacing.$s-100 0 spacing.$xxl-100;
	border-radius: spacing.$xs-200;

	&.MuiPaper-root {
	  padding: spacing.$xs-200 spacing.$m-100;
	}

	& .MuiAlert-icon {
	  padding: spacing.$xs-100 0 0;
	  margin-right: spacing.$s-100;

	  & .MuiSvgIcon-root {
		width: spacing.$m-100;
		height: spacing.$m-100;
	  }
	}

	& .MuiAlert-message {
	  @include font.regular(small);
	  padding: 0;
	}
  }

  &_CostAlert .MuiAlert-icon .MuiSvgIcon-root {
	color: var(--color-icon-information);
  }

  &_CycleAlert .MuiAlert-icon .MuiSvgIcon-root {
	color: var(--color-icon-warning);
  }

  &_CycleAlert.MuiPaper-root {
	background-color: var(--color-surface-warning-light);
  }

  &_SpendingLimit, &_EndDate {
	&.MuiTypography-root {
	  @include font.semibold;
	  margin-bottom: spacing.$l-100;
	}
  }

  &_Periodicity, &_Date {
	@include font.regular;
	color: var(--color-text-subtle);
  }

  &_Buttons {
	display: flex;
	flex-direction: row;
	gap: spacing.$m-100;
	justify-content: flex-end;
  }
}