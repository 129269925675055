@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/button';

@mixin icon {
    &.MuiSvgIcon-root {
        color: var(--color-icon-brand);
        width: spacing.$xl-200;
        height: spacing.$xl-200;
    }
}

@mixin title {
    @include font.semibold(h6);
}

@mixin subtitle {
    @include font.typography-regular;
}

@mixin action {
    @include button.default;
}