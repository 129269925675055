@use 'src/styles/panel';
@use 'src/styles/font';
@use 'src/styles/spacing';

.AccountCreationProgress {
    @include panel.default;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: spacing.$xxl-100 spacing.$xxl-200;

    &_Icon {
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
         circle {
             stroke: url(#gradientSpinnerColors);
         }
        }
    }

    &_Content {
        margin-top: spacing.$l-200;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: spacing.$l-100;
    }

    &_Title {
        @include font.typography-semibold(h6);
    }

    &_Subtitle {
        @include font.typography-regular;
        text-align: center;
        width: 70%;
    }
}
