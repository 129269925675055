@use 'src/styles/spacing';
@use 'src/styles/font';
@use 'src/styles/dimension';
@use 'src/styles/button';

.BudgetForm {

  &_Container {
    padding: spacing.$xl-100;
    width: 40vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  &_TitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: spacing.$xl-200;
  }

  &_Title {
    display: flex;
    align-items: center;

    & .MuiTypography-root {
      @include font.semibold(h6);
      margin: 0 spacing.$s-100;
    }
  }

  &_CloseIcon {
    color: var(--color-icon-subtle)
  }

  &_TitleIcon {
    & .MuiSvgIcon-root {
      color: var(--color-icon-brand);
    }
  }

  &_SpendingLimit {
    padding-bottom: spacing.$xl-200;
  }

  &_Subtitle {
    @include font.typography-semibold(base);
    padding-bottom: spacing.$m-100;
  }

  &_AmountLabel, &_DateLabel, &_TimeUnitLabel, &_PeriodNumberLabel {
    @include font.typography-medium(xsmall);
    color: var(--color-text-subtle)
  }

  &_TextField.MuiFormControl-root {
    margin-bottom: spacing.$s-100;

    & > div > .MuiInputBase-input {
      padding: spacing.$s-100;
    }
  }

  &_InfoAlert.MuiPaper-root {
    padding: 0 spacing.$s-100;
    align-items: center;

    .MuiTypography-root {
      @include font.typography-regular(xsmall);
    }
  }

  &_CycleContainer {
    padding-bottom: spacing.$xl-200;
  }

  &_Description {
    @include font.typography-regular(small);
    padding-bottom: spacing.$m-100;
  }

  &_RadioGroup {
    display: flex;
    flex-direction: row;
    gap: spacing.$s-200;
    padding-bottom: spacing.$m-100;
  }

  &_Radio {
    display: flex;
    flex-direction: row;
    padding: 0 spacing.$s-100;
    border-radius: dimension.$s;
    flex-grow: 1;

    .MuiTypography-root {
      @include font.typography-regular(base);
    }
  }

  &_RadioCheckedIcon {
    color: var(--color-icon-brand);
  }

  &_TimeUnit, &_PeriodNumber, &_FormControl {
    flex-grow: 1;
  }

  &_PeriodNumberTextField > div {
    // Hide the spin buttons in browsers
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &_DatesContainer {
    padding-bottom: spacing.$xxl-500;

    &.customPadding {
      padding-bottom: spacing.$xl-200;
    }
  }

  &_Chip.MuiChip-root {
    border-radius: dimension.$xs-200;
    background-color: var(--color-surface-accent-contrast-light);

    & > span {
      @include font.regular(base);
      color: var(--color-text-accent-contrast);
    }
  }

  &_DateRadioFields {
    display: flex;
    flex-direction: row;
    gap: spacing.$m-100;
  }

  &_DateFields, &_DateRadioGroup {
    display: flex;
    flex-direction: column;
    gap: spacing.$s-100;
  }

  &_DateFields {
    flex-grow: 1.5;
  }

  &_DatePicker {

    &.hasError > div {
      border-color: var(--color-border-danger);
    }

    &.rs-picker-disabled {

      & .rs-input-group,
      & .rs-input {
        background-color: var(--color-surface-disabled-light);

        &:hover {
          border-color: var(--color-border-default);
        }
      }
    }

    .rs-input-group {
      border-radius: dimension.$xs-200;

      &:hover,
      &:focus-within,
      &.rs-input-group-focused {
        border-color: var(--color-border-brand);
      }

      &.rs-input-group-focused {
        outline: none;
      }

      .rs-input {
        border-radius: dimension.$xs-200 0 0 dimension.$xs-200;
      }
    }

  }

  &_Buttons {
    display: flex;
    flex-direction: row;
    gap: spacing.$m-100;
    justify-content: flex-end;

    .MuiButtonBase-root {
      @include button.default;
    }
  }

  &_ErrorHint {
    @include font.typography-regular(base);
    color: var(--color-red-500);
    margin-left: spacing.$m-100;
  }

  &_Creatable {
    & > div:hover,
    & > div:focus-within {
      border-color: var(--color-border-brand);
    }

    & [class$="-control"] {
      box-shadow: none;
    }

    & [class$="-multiValue"] {
      min-width: auto;
      background-color: var(--color-surface-accent-contrast-light);
    }

    & [class$="-MultiValueRemove"] {
      &:hover {
        background-color: inherit;
        color: inherit;
      }

      svg {
        fill: var(--color-icon-accent);
        width: spacing.$m-200;
        height: spacing.$m-200;
      }
    }

    & [class$="-ValueContainer"] {
      padding: spacing.$xs-200;
    }

    &.hasError > div {
      border-color: var(--color-border-danger);
    }
  }
}

.rs-picker-popup-date {
  z-index: 9999;
}