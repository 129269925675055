@use 'src/styles/accountCreation';
@use 'src/styles/spacing';
@use 'src/styles/font';
@use 'src/styles/link';

.AccountCreationCompleted {
    &_Icon {
        @include accountCreation.icon;
        margin-bottom: spacing.$l-200;
    }
    &_Title.MuiTypography-root {
        @include accountCreation.title;
        margin-bottom: spacing.$l-100;
    }
    &_Text.MuiTypography-root {
        @include accountCreation.subtitle;
        margin-bottom: spacing.$xxl-200;
        text-align: center;
    }
    &_SecondaryTitle.MuiTypography-root {
        @include font.semibold(large);
        margin-bottom: spacing.$s-100;
    }
    &_SecondaryText.MuiTypography-root {
        @include font.regular(small);
        color: var(--color-text-subtle);
        text-align: center;
        &:last-of-type {
            margin-bottom: spacing.$xxl-400;
        }
    }
    &_Link {
        @include link.default;
    }
    &_Action {
        @include accountCreation.action;
    }
}
