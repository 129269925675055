/**
 * Use dimension example:
 * 
 *     // someOtherFile.scss
 *     @use 'src/styles/dimension';
 *     
 *     .myClass {
 *         border-radius: dimension.$m;
 *         stroke-width: dimension.$xl-100;
 *     }
 */

$xs:  0.25rem; //  4px
$s:   0.5rem;  //  8px
$m:   1rem;    // 16px
$l:   1.5rem;  // 24px
$xl:  2rem;    // 32px
$xxl: 5rem;    // 80px

$xs-100: 0.125rem; // 2px
$xs-200: 0.25rem;  // 4px

$s-100: 0.5rem;  //  8px
$s-200: 0.75rem; // 12px

$m-100: 1rem;    // 16px
$m-200: 1.25rem; // 20px

$l-100: 1.5rem;  // 24px
$l-200: 1.75rem; // 28px

$xl-100: 2rem;   // 32px
$xl-200: 2.5rem; // 40px
$xl-300: 3rem;   // 48px
$xl-400: 4rem;   // 64px

$xxl-100:   5rem; //  80px
$xxl-150: 5.5rem; //  88px
$xxl-200:   6rem; //  96px
$xxl-300:   7rem; // 128px
$xxl-400:  12rem; // 192px