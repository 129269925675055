@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/spacing';

@mixin default {
    &.MuiTooltip-tooltip {
        @include font.medium(xsmall);
        padding: spacing.$xs-200 spacing.$s-100;
        color: var(--color-text-inverse);
        background-color: var(--color-surface-brand-contrast);
        border-radius: dimension.$xs-200;
    }
}

@mixin error {
    @include default;

    &.MuiTooltip-tooltip {
        background-color: var(--color-surface-danger);
    }
}