@use 'src/styles/font';

@mixin default {
    &.MuiLink-root {
        @include font.semibold(small);
        color: var(--color-text-brand);

        &.MuiLink-underlineAlways {
            text-decoration: underline;
        }
        &.MuiLink-underlineNone {
            text-decoration: none;
        }
        &.MuiLink-underlineHover {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }


    }
}