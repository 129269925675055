@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/spacing';

.EmptyTeams {
    &_Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    &_Text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$s-100;
    }

    &_Title {
        &.MuiTypography-root {
            margin-top: 10px;
            @include font.semibold
        }
    }

    &_Description {
        &.MuiTypography-root {
            color: var(--global-text-primary-color);
            text-align: center;
            @include font.regular(small)
        }
    }

    &_Action {
        @include button.default;
    }
}

@media (max-width: 1360px) {
    .EmptyTeams {
        &_Container {
            max-width: 75%;
        }
    }
}