@use 'src/styles/accountCreation';
@use 'src/styles/spacing';
@use 'src/styles/button';

.AccountCreationWorkspace {
    &_Content {
        gap: spacing.$l-200;
    }

    &_Icon {
        @include accountCreation.icon;
    }

    &_TitleContainer {
        max-width: 604px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: spacing.$l-100;
    }

    &_Title {
        @include accountCreation.title;
    }

    &_Text.MuiTypography-root {
        @include accountCreation.subtitle;
    }
    
    &_List {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$m-100;
    }

    &_List {
        &Icon {
            color: var(--color-icon-brand);
        }

        &Item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: spacing.$s-100;
        }

        &Text.MuiTypography-root {
            @include accountCreation.subtitle;
        }
    }

    &_Actions {
        margin-top: spacing.$s-100;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing.$m-100;
    }

    &_Action {
        @include accountCreation.action;
    }
}