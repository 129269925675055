.CustomEllipsedText {
    &_Container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &_Start.MuiTypography-root {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}