@use 'src/styles/admin';
@use 'src/styles/font';
@use 'src/styles/button';
@use 'src/styles/spacing';
@use 'src/styles/dimension';

.budgetControlContainer {
  @include admin.container;

  .title {
	@include admin.title;
	margin-bottom: spacing.$m-100;
  }

  .subtitle {
	@include admin.subtitle;
	margin-bottom: spacing.$xl-100;
  }

  .tabsContainer {
	border-bottom: 1px solid var(--color-border-default);

	& .MuiButtonBase-root {
	  @include font.medium(large);
	  color: var(--color-text-subtle);
	}

	& .Mui-selected {
	  border-bottom: dimension.$xs-100 solid var(--color-border-brand);
	  transition: all 0.3s ease-out;
	  color: var(--color-text-brand);
	}
  }

  .organizationBudgetContainer {
	display: flex;
	padding: spacing.$m-100 spacing.$m-100 spacing.$l-100 ;
	flex: 1 0 0;
	border-radius: dimension.$xs;
	border: 1px solid var(--color-border-default);
	margin-top: spacing.$l-200;
	justify-content: space-between;

	& .budgetLimitContainer {
	  display: flex;
	  flex-direction: column;

	  & .title {
		@include font.medium(large);
	  }

	  & .subtitle {
		@include font.semibold(h6);
		margin-bottom: 0;

		& svg {
		  color: var(--color-icon-brand);
		  margin-bottom: spacing.$xs-100;
		}
	  }
	}

	.renewedBudget {
	  @include font.regular(small);
	  color: var(--color-text-subtle);
	  margin-top: spacing.$s-100;
	}

	.buttonsRow {
	  display: flex;
	  flex-direction: column;
	  align-items: flex-end;
	  justify-content: space-between;

	  .setOrgLimitButton {
		@include button.default;
		box-shadow: none;
		margin-top: auto;
	  }
	}
  }

  .noTeamsButtonContainer > button {
	margin-top: spacing.$xl-100;
	color: var(--color-text-brand);
	border: 1px solid var(--color-border-brand);
  }

  .deleteBudgetContainer {
	align-self: center;
	flex-grow: 1;

	& button {
	  float: right;
	}
  }

  .tabPanelContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
  }

  .budgetControlNoTeams {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
  }

  .teamsBudgetsContainer {
	margin-top: spacing.$xl-100;
	width: 100%;
	display: flex;
	padding: spacing.$m-100;
	flex-direction: column;
	align-items: flex-start;
	gap: spacing.$m-100;
	align-self: stretch;
	border-radius: spacing.$xs-200;
	border: 1px solid var(--color-border-default);
  }

  .budgetControlTeamsContainer {
	margin-top: spacing.$xl-100;
	width: 100%;

	.MuiDataGrid-toolbarQuickFilter {
	  border-radius: spacing.$xs-200;
	  border: 1px solid var(--color-border-default);
	  padding-bottom: 0;
	  margin-bottom: spacing.$l-100;

		& .MuiInputBase-root {
			height: spacing.$xl-100;
			background-color: var(--color-surface-tertiary-light);
		}

		& .MuiInputBase-input::placeholder {
			@include font.regular;
		}

		& .MuiSvgIcon-root {
			color: var(--color-icon-default);
			margin-left: spacing.$s-100;
			height: dimension.$m-100;
			width: dimension.$m-100;
		}

	  & .MuiInputBase-root::before, & .MuiInputBase-root:hover:before, & .MuiInputBase-root:after {
		border: none;
	  }

	  &:has(.Mui-focused) {
		border-color: var(--color-border-brand);
	  }
	}

	& .MuiDataGrid-main {

	  padding: spacing.$m-100;
	  border-radius: spacing.$xs-200;
	  border: 1px solid var(--color-border-default);

	  & .teamName {
		@include font.regular;
	  }

	  & .teamCell {
		display: flex;
		align-items: center;
		padding: spacing.$m-100 0;
	  }

	  & .teamLimit, & .organizationLimit {
		display: flex;
		padding: spacing.$xs-200 spacing.$s-100;
		align-items: center;
		gap: spacing.$s-100;
		border-radius: spacing.$xs-200;
		background: var(--color-surface-accent-contrast-light);
		@include font.regular(small);
	  }

	  & .teamLimit {
		background: var(--color-surface-brand-contrast-light);
		color: var(--color-text-brand-contrast);
	  }

	  & .spendingResetContainer {
		display: flex;
		gap: spacing.$xs-200;


		& .spendingResetLabel.MuiTypography-root {
		  @include font.regular(small);
		  color: var(--color-text-subtle);
		}

		& .spendingResetDate.MuiTypography-root {
		  @include font.medium(small);
		}
	  }

	  & .MuiDataGrid-row:hover {
		background-color: var(--color-surface-default);
	  }
	}

	.actionIcon {
	  color: var(--color-icon-brand);
	}
  }
}


.unlimitedTooltip.MuiTooltip-tooltip {
  @include font.medium(xsmall);
  border-radius: dimension.$xs;
  background-color: var(--color-purple-700);
  padding: spacing.$xs-200 spacing.$s-100;
  width: 265px;
  color: var(--color-text-inverse);
}

.spendingLimit {
  &MoreIcon > svg {
	color: var(--color-icon-brand);
  }

  &MoreMenu {
	& > .MuiPaper-root {
	  padding: spacing.$l-100 spacing.$l-100 spacing.$l-200;
	  width: 173px;
	  border-radius: dimension.$xs;
	  border: 1px solid var(--color-border-default);
	  box-shadow: var(--shadow-default);

	  & > ul {
		padding: 0;
	  }
	}

	.spendingLimitMenuItem {
	  padding: 0;

	  &:hover {
		background-color: transparent;
	  }
	}

	.spendingLimitMenuItemIcon {
	  color: var(--color-icon-brand);
	  width: spacing.$m-200;
	  height: spacing.$m-200;
	}

	.spendingLimitMenuItemText {
	  @include font.regular;
	  margin-left: spacing.$s-100;
	}

	.menuItemDivider.MuiDivider-root {
	  background-color: var(--color-border-divider);
	  margin: spacing.$m-100 0;
	}
  }
}