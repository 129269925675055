@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/dimension';
@use 'src/styles/spacing';

.dialogContainer {
  & .MuiPaper-root {
    box-shadow: none;
    border: solid 1px var(--global-outline-color);
    border-radius: dimension.$xs-200;
    max-width: 750px;
  }

  & .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font.typography-semibold(h6)
  }

  & .dialogContent {
    &.MuiDialogContent-root {
      padding: spacing.$m-100 spacing.$l-100;
    }
    & .MuiTypography-root{
      @include font.regular(base);
      color: var(--color-text-subtle);
    }

    & .dialogContentText:nth-child(2),
    & .dialogContentText:nth-last-child(2) {
      padding-bottom: spacing.$m-100;
    }
  }

  & .newGroupNameTextfield {
    margin-top: spacing.$m-200;
  }
  
  & .dialogCloseIcon {
    cursor: pointer;
    color: var(--color-icon-subtlest);
  }

  & .deleteButton {
    color: var(--color-text-danger);
    border-color: var(--color-border-danger);
    &:hover {
      color: var(--color-text-danger);
      background-color: var(--color-surface-danger-contrast-light);
      border-color: var(--color-border-danger);
    }
  }

  & .dialogDeletionActionsContainer,
  & .dialogActionsContainer,
  & .limitDialogDeletionActionsContainer{
    padding-bottom: spacing.$l-100;
  }

  & .limitDialogDeletionActionsContainer{
    padding-left: spacing.$l-100;
  }

  & .dialogDeletionActionsContainer {
    display: flex;
    justify-content: center;
  }

  & .limitDialogDeletionActionsContainer{
    display: flex;
    justify-content: left;
  }
}

