@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/dimension';

.BudgetSummary {

  &_Container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: spacing.$xl-100 spacing.$xl-100 spacing.$xxl-100 spacing.$xl-100;
    width: 40vw;
  }

  &_TitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: spacing.$xxl-100;

    & .MuiSvgIcon-root {
      color: var(--color-icon-subtlest);
    }
  }

  &_Title {
    display: flex;
    align-items: center;
    gap: spacing.$s-100;

    & .MuiSvgIcon-root {
      color: var(--color-icon-brand);
    }

    & .MuiTypography-root {
      @include font.semibold(h6);
    }

  }

  &_InfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacing.$s-100;
    background-color: var(--color-surface-information-light);
    padding: spacing.$xs-200 spacing.$m-100;
    border-radius: dimension.$xs-200;
    margin-bottom: spacing.$xxl-100;

    & .MuiTypography-root {
      @include font.regular(small);
    }
  }

  &_InfoItemContainer {
    display: flex;
    flex-direction: row;
    gap: spacing.$s-100;
    margin-bottom: spacing.$l-100;
    align-items: center;
  }

  &_InfoItemLabel {
    @include font.typography-semibold;
  }

  &_InfoItemValue {
    &.MuiTypography-root {
      @include font.regular;
      color: var(--color-text-subtle);
    }
  }

  &_CloseButtonContainer {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    }
}