@use 'src/styles/spacing';
@use 'src/styles/dimension';


// This might be one of the unique places of the app
// where colors have to be used directly
.FileUploadIcon {
    min-width: spacing.$m-200;
    min-height: spacing.$m-200;
    max-width: spacing.$m-200;
    max-height: spacing.$m-200;
    width: spacing.$m-200;
    height: spacing.$m-200;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: dimension.$xs;
    background-color: var(--color-icon-accent);
    & svg.MuiSvgIcon-root {
        width: spacing.$m-100;
        height: spacing.$m-100;
    }
    & svg, & path {
        fill: var(--color-icon-inverse);
    }

    &_csv {
        background-color: var(--color-green-700);
    }
    &_doc, &_docx {
        background-color: var(--color-blue-800);
    }
    &_json {
        background-color: var(--color-purple-800);
    }
    &_pdf {
        background-color: var(--color-red-600);
    }
    &_pptx {
        background-color: var(--color-orange-600);
    }
    &_txt {
        background-color: var(--color-navy-600);
    }
    &_xls {
        background-color: var(--color-green);
    }
}