@use 'src/styles/spacing';
@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/button';
@use 'src/styles/flag';
@use 'src/styles/switch';

.savePromptContainer {
  & .MuiPaper-root {
    max-width: 700px;
    width: 700px;
    padding: spacing.$l-200 spacing.$l-100;
  }

  .savePromptTitle {
    @include font.semibold(h6);
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: spacing.$s-100;

    & .closeIcon {
      color: var(--color-icon-subtlest);
      font-size: large;
    }
  }

  .savePromptDialogContent {
    padding: spacing.$xs-100;
  }

  .savePromptDescriptionText.MuiTypography-root,
  .privacyToggleContainer > p,
  .helpText {
    @include font.regular(small);
    color: var(--color-text-subtle);
  }

  .savePromptCompilationContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .fieldLabel {
      margin-top: spacing.$xl-100;
    }

    .promptCompilationField {
      border-radius: dimension.$xs;
      border: 1px solid var(--color-border-default);
      padding: spacing.$m-100;
      margin-bottom: spacing.$s-100;
      flex-grow: 1;
      overflow: scroll;

      & .markdownEditor > div > div {
        &:focus {
          outline: none;
        }

        & > p {
          margin: 0;
        }
      }
    }

    .newPromptAlert {
      &.MuiPaper-root {
        @include flag.slim(small);
        justify-content: flex-start;
        width: fit-content;

        & .MuiAlert-icon {
          font-size: dimension.$m;
        }
      }
    }
  }

  .fieldLabel {
    @include font.medium(xsmall);
    color: var(--color-text-subtle);
    margin-top: spacing.$m-100;
  }

  .savePromptName {
    & > .textFieldInput {
      & > .MuiInputBase-root {
        height: spacing.$xl-100;

        & > .MuiOutlinedInput-notchedOutline {
          border: 1px solid var(--color-border-default);
        }

        &.Mui-focused fieldset {
          border: dimension.$xs-100 solid var(--color-border-brand);
        }
      }

      & .MuiInputBase-input::placeholder {
        color: var(--color-text-disabled);
        opacity: 1;
      }
    }
  }

  .creatableSelect {
    width: 50%;

    & input, & input:focus  {
      box-shadow: none;
    }

    & [class$="-placeholder"] {
      @include font.regular(small);
      color: var(--color-text-disabled);
    }

    & [class$="-control"] {
      border: 1px solid var(--color-border-default);
      height: spacing.$xl-100;
    }
  }

  .privacyContainer {
    & .privacyToggleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: spacing.$s-100;

      & .privacyToggle {
        @include switch.default;
      }
    }
  }

  .sectionTitle {
    @include font.semibold;
    margin-top: spacing.$xl-100;
  }

  .promptInputValue {
    @include font.regular(small);
    margin-top: spacing.$s-100;
  }

  .savePromptActionsContainer {
    justify-content: flex-start;
    padding: 0;
    margin: spacing.$xl-100 0;

    & .savePromptButton {
      @include button.default;
    }
  }

  .helpContainer {
    display: flex;

    .helpIcon {
      color: var(--color-icon-information);
      font-size: dimension.$m-200;
    }

    .helpText:first-child {
      color: var(--color-text-information);
    }
  }
}