@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/spacing';

.accountDomainRow {
  display: flex;
  border-bottom: 1px solid var(--color-border-divider);
  padding: spacing.$s-100 0;

  &:last-child {
	border-bottom: none;
  }

  .accountDomainName {
	flex-grow: 1;
	align-content: center;
	color: var(--color-text-default);

	& .MuiTypography-root {
	  @include font.typography-regular('base');
	}
  }
}

.domainActionIcon {
  @include button.icon;
}