@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/switch';
@use 'src/styles/button';

.AccountCreationForm_Models {
  align-items: start;

  &Container {
	margin-top: spacing.$xl-100;
	border: 1px solid var(--color-border-default);
	border-radius: spacing.$xs-200;
	padding: spacing.$s-100 spacing.$l-100;
	gap: 14px;
  }

  &Header.MuiTypography-root, &NewModelHeader.MuiTypography-root {
	@include font.semibold;
	margin: spacing.$l-100 0 spacing.$s-100;
  }

  &NewModelHeader.MuiTypography-root {
	margin-top: spacing.$xxl-300;
  }

  &Subtitle.MuiTypography-root {
	@include font.regular(small);
	color: var(--color-text-subtle);
  }

  &Header2 {
	margin-top: spacing.$l-100;
  }

  &Item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #CCCCCC;
	padding: spacing.$m-100 0;

	&:first-child {
	  padding-top: spacing.$s-100;
	}

	&:last-child {
	  border: none;
	  padding-bottom: spacing.$s-100;
	}
  }

  &Name, &Actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: spacing.$s-100;
	color: var(--global-text-primary-color);
  }

  &Name svg {
	width: spacing.$m-200;
	height: spacing.$m-200;
  }

  &Icon {
	@include button.icon;
  }

  &Extra.MuiTypography-root {
	margin-left: spacing.$xl-100;
  }

  &Switch {
	@include switch.default;
  }
}

.AccountCreationForm_NewModel {
	display: flex;
	flex-direction: column;
	gap: spacing.$s-100;
	margin-top: spacing.$xl-100;
	margin-bottom: spacing.$s-100;

	&MandatoryFields {
		display: flex;
		gap: 8px;
		align-items: flex-end;
	}

	&Button {
		@include button.default;
		flex-grow: 1;
	}

	&Fieldname.MuiFormLabel-root {
		@include font.medium(xsmall);
		color: var(--color-text-subtle);
	}

	&AiModel {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 45%;

		@media (max-width: 1000px) {
			flex-basis: 50%;
		}
	}

	&AiName {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 38%;
		@media (max-width: 1000px) {
			flex-basis: 33%;
		}
	}

	&AiApiKey {
		width: 84.5%;
	}

	&Creatable {
		height: spacing.$xl-100;

		& [class$="-control"] {
			height: spacing.$xl-100;
		}

		& [class$="-multiValue"] {
			min-width: auto;
			background-color: var(--color-surface-accent-contrast-light);
		}

		& [class$="-MultiValueRemove"] {

			&:hover {
				background-color: inherit;
				color: inherit;
			}

			svg {
				fill: var(--color-icon-accent);
				width: spacing.$l-100;
				height: spacing.$l-100;
			}
		}

		& [class$="-ValueContainer"] {
			flex-wrap: nowrap;
		}


		&.hasError {
			border: 1px solid #D3302F;
			border-radius: 5px;

			& [class$="-placeholder"] {
				color: #D3302F;
				font-weight: 500;
			}
		}

		& > div > div > div:nth-child(2) > input, [class$="-placeholder"] {
			color: rgba(0, 0, 0, 0.30);
			box-shadow: none;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&SelectedValue {
		display: flex;
		gap: spacing.$xs-200;
		align-items: center;

		& .MuiTypography-root {
			@include font.regular(small);
		}

		&.values_disabled {
			color: var(--color-text-disabled);

			& svg {
				opacity: 0.2;
			}
		}
	}
}